<template>

    <div class="client"><a href="https://www.maldonado.gub.uy/" title="Intendencia de Maldonado" target="__blank"><img alt="Intendencia de Maldonado" src="../assets/images/logos/marcas-imm.png" /></a></div>
    <div class="client"><a href="https://www.maldonado.gub.uy/municipio/puntadeleste" title="Municipio de Punta del Este" target="__blank"><img alt="Municipio de Punta del Este" src="../assets/images/logos/marcas-municipio.png" /></a></div>
    <div class="client"><a href="https://centroculturalkavlin.org/" title="Kavlin Centro Cultural" target="__blank"><img alt="Kavlin Centro Cultural" src="../assets/images/logos/marcas-kavlin.png" /></a></div>

</template>

<script>
export default{
    name: 'ClientsFooter'
}
</script>
