module.exports = {
    environment: "dev",  // dev / prod
    mode: "normal", // normal / mantenimiento / construccion
    locales: 'es,en',
    defaultLocale: 'es',
    fallbackLocale: 'en',
    metas: {
        title : "Paseo de las Américas",
        description : "Paseo de las Américas",
    } 
}