module.exports = [
    {
        "path": "metamorfosis", 
        "name": "Metamorfosis",
        "active": 1,
        "author": "Edgar Negret", 
        "country": "Colombia",
        "birth": "Colombia, 1920-2012",
        "slides": [
            '/img/esculturas/metamorfosis-1.jpg',
            '/img/esculturas/metamorfosis-2.jpg'
        ]
    },
    {
        "path": "el-pajaro-caido", 
        "name": "El pájaro caído",
        "active": 1,
        "author": "Hermann Guggiari", 
        "country": "Paraguay",
        "birth": "Paraguay, 1924-2012",
         "slides": [
            '/img/esculturas/el-pajaro-caido-1.jpg',
            '/img/esculturas/el-pajaro-caido-2.jpg'
        ]
    },
    {
        "path": "la-mano", 
        "name": "La mano",
        "active": 1,
        "author": "Mario Irarrazabal",
        "country": "Chile",
        "birth": "Chile, 1940",
        "website": "https://www.marioirarrazabal.cl",
        "slides": [
            '/img/esculturas/la-mano-1.jpg',
            '/img/esculturas/la-mano-2.jpg'
        ]
    },
    {
        "path": "homenaje-a-punta-del-este", 
        "name": "Homenaje a Punta del Este",
        "active": 1,
        "author": "Gyula Kosice", 
        "country": "Argentina",
        "birth": "CSK, 1924 - Argentina, 2016",
        "website": "https://kosice.com.ar",
        "slides": [
            '/img/esculturas/homenaje-a-punta-del-este-1.jpg',
            '/img/esculturas/homenaje-a-punta-del-este-2.jpg'
        ]
    },
    {
        "path": "el-descubrimiento", 
        "name": "El descubrimiento", 
        "active": 1,
        "author": "Nelson Ramos", 
        "country": "Uruguay", 
        "birth": "Uruguay, 1932-2006",
        "website": "https://mnav.gub.uy/cms.php?a=397",
        "slides": [
            '/img/esculturas/el-descubrimiento-1.jpg',
            '/img/esculturas/el-descubrimiento-2.jpg',
            '/img/esculturas/el-descubrimiento-3.jpg',
            '/img/esculturas/el-descubrimiento-4.jpg'
        ]
    },
    {
        "path": "forma-ciega", 
        "name": "Forma ciega", 
        "active": 1,
        "author": "Waltercio Caldas", 
        "country": "Brasil", 
        "birth": "Brasil, 1946",
        "website": "https://www.xippas.com/artists/waltercio-caldas/",
        "slides": [
            '/img/esculturas/forma-ciega-1.jpg',
            '/img/esculturas/forma-ciega-2.jpg'
        ]
    },
    {
        "path": "el-signo", 
        "name": "El signo",
        "active": 1,
        "author": "Francisco Matto", 
        "country": "Uruguay",
        "birth": "Uruguay 1911-1995",
        "website": "https://franciscomatto.org",
        "slides": [
            '/img/esculturas/el-signo-1.jpg',
            '/img/esculturas/el-signo-2.jpg'
        ]
    },
    {
        "path": "la-lucha-entre-el-orden-y-el-caos",
        "name": "La lucha entre el orden y el caos",
        "active": 1,
        "author": "Ennio Iommi",
        "country": "Argentina",
        "birth": "Argentina, 1926-2013",
        "website": "https://www.enioiommi.com",
        "slides": [
            '/img/esculturas/la-lucha-entre-el-orden-y-el-caos-1.jpg',
            '/img/esculturas/la-lucha-entre-el-orden-y-el-caos-2.jpg'
        ]
    },
    {
        "path": "observatorio-austral",
        "name": "Observatorio austral",
        "active": 1,
        "author": "Jaques Bedel",
        "country": "Argentina",
        "birth": "Argentina, 1947",
        "website": "https://jacquesbedel.com",
        "slides": [
            '/img/esculturas/observatorio-austral-1.jpg',
            '/img/esculturas/observatorio-austral-2.jpg'
        ]
    }
] 


/*

1) https://paseodelasamericas.com/metamorfosis
2) https://paseodelasamericas.com/el-pajaro-caido
3) https://paseodelasamericas.com/la-mano
4) https://paseodelasamericas.com/homenaje-a-punta-del-este
5) https://paseodelasamericas.com/el-descubrimiento
6) https://paseodelasamericas.com/forma-ciega
7) https://paseodelasamericas.com/el-signo
8) https://paseodelasamericas.com/la-lucha-entre-el-orden-y-el-caos
9) https://paseodelasamericas.com/observatorio-austral


La Mano (Mario Irarrazabal, Chileno) https://paseodelasamericas.com/la-mano
El Pájaro Caído (Herm La lucha entre el orden y el caos (Enio Iommi, Argentino) https://paseodelasamericas.com/el-pajaro-caido
Homenaje a Punta del Este (Gyula Kosice, Argentino) https://paseodelasamericas.com/homenaje-a-punta-del-este
El descubrimiento (Nelson Ramos, Uruguayo) --> https://paseodelasamericas.com/el-descubrimiento
Forma Ciega (Waltercio Caldas, Brasileño) --> https://paseodelasamericas.com/forma-ciega
El signo (Francisco Matto, Uruguayo) --> https://paseodelasamericas.com/el-signo
La lucha entre el Orden y El Caos (Ennio Iommi, Uruguayo) --> https://paseodelasamericas.com/la-lucha-entre-el-orden-y-el-caos
Observatorio austral (Jaques bedel, Uruguayo) --> https://paseodelasamericas.com/observatorio-austral


*/